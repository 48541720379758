import { useEffect } from 'react';

const PaginatorButton = ({ label, url, active, onClick, className = '' }) => (
  <a
    href={url}
    className={`p-[5px] border no-underline hover:border-black ${active ? 'bg-pink text-white border-[#c0c0c0]' : 'text-pink'} ${className}`}
    onClick={onClick}
  >
    {label}
  </a>
);

const Paginator = ({ paginationData, onPageChange }) => {
  const {
    current_page,
    last_page,
    first_page_url,
    next_page_url,
    prev_page_url,
  } = paginationData;

  let leading = "";

  leading = first_page_url.replace(/\/pahina(\?page=\d+)?$/, '');

  if(first_page_url.includes("/search")) {
    leading = first_page_url.split('?')[0];
  }

  if(first_page_url.includes("/ip-activity")) {
    leading = first_page_url.split('?')[0];
  }


  const getPageUrl = (page) => `${leading}/pahina/${page}`;

  const renderPageButtons = () => {
    const buttons = [];
    const totalButtons = Math.min(5, last_page);
    let start = Math.max(1, current_page - 2);
    let end = Math.min(last_page, start + totalButtons - 1);

    if (end - start + 1 < totalButtons) {
      start = Math.max(1, end - totalButtons + 1);
    }

    for (let i = start; i <= end; i++) {
      buttons.push(
        <PaginatorButton
          key={i}
          label={i.toString()}
          url={getPageUrl(i)}
          active={i === current_page}
        />
      );
    }

    return buttons;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        window.location.href = getPageUrl(Math.max(1, current_page - 1)); // Navigate to "back"
      }
      if (event.key === 'ArrowRight') {
        window.location.href = getPageUrl(Math.min(last_page, current_page + 1)); // Navigate to "next"
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [current_page, last_page]); // Dependencies for the effect  

  return (
    <div className="flex justify-center items-center space-x-2">
      <PaginatorButton
        label="balik"
        url={getPageUrl(Math.max(1, current_page - 1))}
        className="w-20 text-center border-white hover:border-black"
        active={false}
      />
      {renderPageButtons()}
      <PaginatorButton
        label="susunod"
        url={getPageUrl(Math.min(last_page, current_page + 1))}
        className="w-20 text-center border-white hover:border-black"
        active={false}
      />
    </div>
  );
};

export default Paginator;